.error-main {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;flex-direction: column;
}

.error-main img {
    height: 50%;
    width: auto;
    border: 2px solid #760014;
}

.error-main h1 {
    text-transform: uppercase;
    font-size: 26px;
}

.error-main button {
    border: 2px solid #760014;
    color: #760014;
    background: #fff;
    padding: 10px;
    font-weight: 600;
}

.error-main button:hover {
    background: #760014;
    color: #fff;
    cursor: pointer;
}