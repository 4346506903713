.navbar {
    width: 100vw;
    height: 100px;
    position: fixed;
    background: #E9E0DB;
    border-bottom: 3px solid #760014;
    z-index: 20;
    overflow: hidden;
}

.navbar .navbar-content {
    max-width: 1400px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}

/* logo */

.navbar .navbar-content .logo-container {
    width: 150px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-container:hover {
    cursor: pointer;
}

.logo-container img {
    height: 70%;
    width: auto;
}

/* buttons */

.navbar .navbar-content .button-container {
    display: flex;
}

.button-container a {
    text-decoration: none;
    width: 150px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    color: #760014;
    font-size: 16px;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-weight: bold;
    text-transform: capitalize;
}

.button-container a:hover {
    color: white;
    background: #760014;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .navbar {
        height: 60px;
    }

    .navbar .navbar-content .logo-container {
        width: 25%;
    }

    .logo-container img {
        height: 60%;
    }

    .button-container {
        width: 75%;
    }

    .button-container a {
        font-size: 10px;
        width: 100%;
    }
}