.footer {
    width: 100%;
    height: auto;
    background: #E9E0DB;
}

.footer .footer-content {
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    display: block;
    padding: 160px 0 80px;
}

.footer .footer-content .title-container {
    position: absolute;
    left: 0;
    color: #000;
    font-weight: 800;
    font-size: 48px;
    text-transform: uppercase;
    color: #760014;
}

.footer .footer-content .content-container {
    display: flex;
    height: 500px;
}

.content-container .detail-container {
    width: 50%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid #760014;
    opacity: 0;
    transform: translateY(50);
}

.detail-container .address,
.detail-container .line {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.detail-container .detail-header {
    padding-bottom: 10px;
    font-weight: 800;
    font-size: 24px;
}

.detail-container .detail-subheader {
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-size: 16px;
    font-weight: 900;
}

.detail-container .detail-desc {
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-size: 16px;
    font-weight: normal;
}

.content-container .map-container {
    width: 50%;
}

.content-container .map-container iframe {
    height: 500px;
    width: calc(100% - 20px);
    padding-left: 20px;
    border: none;
}

@media screen and (max-width: 1400px) {
    .footer .footer-content .title-container {
        font-size: 42px;
        top: 80px;
        left: 20px
    }

    .footer .footer-content .content-container {
        padding: 0 20px;
    }
}

@media screen and (max-width: 600px) {
    .footer .footer-content {
        padding: 40px 0;
    }

    .footer .footer-content .title-container {
        position: unset;
        padding-left: 20px;
        font-weight: 800;
        font-size: 20px;
    }

    .footer .footer-content .content-container {
        flex-direction: column;
        height: auto;
    }

    .content-container .detail-container {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #760014;
        padding: 40px 0;
        gap: 16px;
    }

    .detail-container .detail-header {
        padding-bottom: 10px;
        font-weight: 800;
        font-size: 14px;
    }

    .detail-container .detail-subheader {
        font-family: 'M PLUS Rounded 1c', sans-serif;
        font-size: 12px;
        font-weight: 900;
    }

    .detail-container .detail-desc {
        font-family: 'M PLUS Rounded 1c', sans-serif;
        font-size: 10px;
        font-weight: normal;
    }

    .content-container .map-container {
        width: -webkit-fill-available;
    }

    .content-container .map-container iframe {
        padding-top: 20px;
        padding-left: 0;
        height: 350px;
    }
}