.track-record {
    width: 100vw;
    height: auto;
    overflow: hidden;
}

.track-record .record-container {
    display: flex;
    flex-direction: column;
    gap: 100px;
}

.record-container .record-content {
    border-top: 2px solid #760014;
    border-bottom: 2px solid #e2e2e2;
    display: block;
    overflow-y: scroll;
    width: 100%;
    background: url(../../Assets/Record/record-bg-bw.png);
    background-size: cover;
}

.record-content .records {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.record-content .records .record {
    font-size: 16px;
    padding: 20px;
    background: #F9F1EC;
    border-left: 2px solid #760014;
    border-top: 2px solid #F9F1EC;
    border-right: 2px solid #F9F1EC;
    border-bottom: 2px solid #F9F1EC;
    transform: translateX(-100);
    opacity: 0;
}

.record .bld {
    font-weight: bold;
}

.record-content .records .record:hover {
    background: #E9E0DB;
    border: 2px solid #760014;
}

.record-container .record-content {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.record-container .record-content::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 600px) {
    .record .record-container {
        gap: 60px;
    }

    .record-content .records .record {
        padding: 10px;
        font-size: 10px;
    }
}