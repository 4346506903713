.service {
    display: block;
    min-height: calc(100vh - 100px);
    background: #fff;
    padding-top: 100px;
    max-width: 100vw;
    overflow: hidden;
}

.service .service-container {
    width: 100vw;
}

.service-container .header {
    width: 100vw;
    background: #F9F1EC;
}

.service-container .header .service-header {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    padding: 50px 0;
}

.service-header .text {
    width: calc(100% / 3);
    color: black;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-size: 32px;
    font-weight: 900;
    padding-left: 40px;
    text-transform: uppercase;
    display: inline;
    gap: 5px;
    align-items: center;
}

.service-header .text .red {
    color: #760014;
    text-decoration: underline;
}

.service-header .expand .btn {
    margin: 0 auto;
    display: block;
    height: 30px;
    width: 30px;
    background: none;
    border: none;
}

.service-header .buttons {
    width: calc(100% / 3 * 2);
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    gap: 20px;
}

.service-header .buttons .button {
    font-size: 16px;
    background: #fff;
    color: #000;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-weight: 900;
    padding: 10px;
    border: none;
    border-bottom: 2px solid #760014;
}

.service-header .buttons .button.active {
    background: #760014;
    color: #fff;
}

.service-header .buttons .button:hover {
    cursor: pointer;
    background: #760014;
    color: #fff;
}

.service-container .content {
    max-width: 1400px;
    margin: 0 auto;
    height: calc(100vh - 100px);
    background: #f9f1ec;
}

.service-container .content .content-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.content-container .side-image {
    width: calc(100% / 3);
    overflow: hidden;
}

.content-container .side-image img {
    height: 100%;
    transform: translateX(-400px);
}

.content-container .service-detail {
    width: calc(100% / 3 * 2);
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
}

.content-container .service-detail-header {
    display: block;
    display: flex;
    flex-direction: column;
    padding: 50px 20px 20px 40px;
    width: auto;
}

.content-container .service-detail-title {
    display: block;
    font-weight: 900;
    font-size: 20px;
    padding: 30px 0 20px 0;
}

.content-container .service-detail-subtitle {
    display: block;
    font-weight: 800;
    font-size: 18px;
    padding: 20px 0 10px 0;
}

.content-container .service-detail-header .service-header {
    font-size: 48px;
    color: #000;
    font-weight: 900;
}

.content-container .service-detail-header .service-subheader {
    font-size: 20px;
    color: #760014;
    font-weight: 900;
}

.content-container .service-detail-content {
    display: block;
    padding: 0 20px 20px 40px;
    width: auto;
    height: 100%;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.content-container .service-detail-content::-webkit-scrollbar {
    display: none;
}

.content-container .service-detail-content p,
.content-container .service-detail-content li,
.content-container .service-detail-content th,
.content-container .service-detail-content td {
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
}

.content-container .service-detail-content table {
    border-spacing: 0;
}

.content-container .service-detail-content th {
    text-transform: capitalize;
    background: #760014;
    color: #fff;
    font-weight: bold;
    padding: 10px 0;
}

.content-container .service-detail-content td:nth-child(1) {
    text-align: center;
    font-weight: 800;
    background: #E9E0DB;
}

.content-container .service-detail-content td {
    padding: 10px;
}

.content-container .service-detail-content li.red {
    color: #760014;
}

.content-container .service-detail-content ol li {
    padding-bottom: 10px;
}

.content-container .service-detail-content .iptv-table {
    background: #E9E0DB;
    display: block;
    padding: 20px;
    margin-top: 50px;
}

.iptv-table .iptv-header {
    display: block;
    font-weight: 900;
    font-size: 20px;
    padding-bottom: 30px;
}

.iptv-table .iptv-img {
    width: 100%;
    height: auto;
}

.iptv-table .iptv-img img {
    width: 100%;
}

@media screen and (max-width: 1400px) {
    .service-header .buttons {
        padding-right: 20px;
    }
}

@media screen and (max-width: 1000px) {

    .service-container .content {
        padding: 0;
        margin: 0 20px;
        height: auto;
    }

    .service-container .content .content-container {
        flex-direction: column;
    }

    .content-container .service-detail-header,
    .content-container .service-detail-content {
        padding: 0 0 20px 0;
    }

    .content-container .side-image {
        width: 100%;
        height: calc((100vw / 16) * 9);
        overflow: hidden;
    }

    .content-container .side-image img {
        width: 100%;
        height: auto;
        transform: translateX(0);
    }

    .content-container .service-detail {
        width: auto;
        height: 100%;
        padding: 40px 20px 0 20px;
    }
}

@media screen and (max-width: 600px) {
    .service {
        min-height: calc(100vh - 60px);
        padding-top: 60px;
    }

    .service-container .header .service-header {
        flex-direction: column;
        gap: 30px;
        padding: 52px 0;
    }

    .service-header .text {
        width: 100%;
        color: black;text-align: center;
        padding-left: 0;
        font-size: 20px;
    }

    .service-header .buttons {
        width: auto;
        justify-content: center;
        padding: 0 20px;
        gap: 10px;
    }

    .service-header .buttons .button {
        font-size: 10px;
        padding: 10px;
    }

    .content-container .side-image img {
        width: 100%;
        height: 100%;
        transform: translateY(0);
        object-fit: cover;
    }

    .content-container .service-detail {
        width: auto;
        height: 100%;
        padding: 20px 10px 0 10px;
    }

    .content-container .service-detail-header .service-header {
        font-size: 20px;
    }

    .content-container .service-detail-header .service-subheader {
        font-size: 14px;
    }

    .content-container .service-detail-title {
        font-size: 14px;
    }

    .content-container .service-detail-content p,
    .content-container .service-detail-content li,
    .content-container .service-detail-content th,
    .content-container .service-detail-content td {
        font-size: 10px;
    }
}