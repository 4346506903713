@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&display=swap');

body {
  max-width: 100vw;
  overflow-x: hidden;
}

.breakpoint {
  display: block;
  width: 100%;
  height: 200px;
}

.breakpoint-service {
  display: block;
  width: 100%;
  height: 100px;
}

/* toast */

.Toastify__toast {
  border-radius: 0;
}

/* header */

.header-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  opacity: 0;
}

.main-header {
  font-family: 'Roboto', sans-serif;
  font-size: 48px;
  font-weight: 900;
  color: #000;
  text-transform: uppercase;
  text-align: center;
}

.main-header .red {
  color: #760014;
}

.main-subheader {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 300;
  color: #000;
  text-align: center;
  padding: 0 20px;
}

@media screen and (max-width: 600px) {
  .breakpoint {
      height: 100px;
  }

  .breakpoint-service {
    height: 40px;
}

  .header-container {
    gap: 10px;
  }

  .main-header {
    font-size: 20px;
  }

  .main-subheader {
    font-size: 10px;
  }
}