.scroll-container {
    display: block;
    background: #760014;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    bottom: 80px;
    right: 40px;
    z-index: 10;
    opacity: 0;
}

.scroll-container:hover {
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .scroll-container {
        display: block;
        background: #760014;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        bottom: 46px;
        right: 24px;
        z-index: 10;
        opacity: 0;
    }
}