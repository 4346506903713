.contact {
    display: block;
    min-height: calc(100vh - 100px);
    background: #F9F1EC;
    padding-top: 100px;
}

.contact > .header-container {
    padding: 60px 0 40px;
}

.contact .contact-container {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    height: calc(100vh - 257px);
}

.contact-container .form-container {
    width: 40%;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.contact-container .form-container::-webkit-scrollbar {
    display: none;
}

.contact-container .form-container .form-container-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 40px;
    width: 100%;
}

.contact-container .form-container::-webkit-scrollbar {
    display: none;
}

.form-container .form-content {
    max-width: 500px;
    margin: 0 auto;
    padding: 0 20px 40px 20px;
}

.form-container .header-container .main-subheader {
    text-align: left;
    padding: 0;
}

.form-container .form-content .form-contact {
    padding: 40px 20px;
    background: #E9E0DB;
    display: flex;
    flex-direction: column;
    gap: 28px;
}

.form-contact .form-row {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.form-contact .form-row .label-error{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.label-error .error-name {
    font-size: 12px;
    font-weight: 500;
    color: red;
}

.form-contact .form-row label {
    font-size: 12px;
    font-weight: 500;
}

.form-contact .form-row input,
.form-contact .form-row textarea {
    font-family: 'Roboto', sans-serif;
    background: #fffbf8;
    padding: 10px;
    border: none;
    font-size: 12px;
    letter-spacing: 1px;
}

.form-contact .form-row textarea {
    resize: vertical;
    min-height: 100px;
}

.form-contact .form-row button {
    width: fit-content;
    padding: 10px 20px;
    border: 2px solid #760014;
    background: none;
    color: #760014;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    transition: 0.3s;
    margin: 0 auto;
}

.form-contact .form-row button:hover {
    cursor: pointer;
    background: #760014;
    color: #E9E0DB;
}

.form-container .contact-info {
    margin: 0 auto;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.contact-info .email-info a,
.contact-info .phone-info a {
    color: white;
    background: #760014;
    padding: 20px;
    transition: 0.3s;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
}

.contact-info .email-info a:hover,
.contact-info .phone-info a:hover {
    cursor: pointer;
    color: white;
    background: #a81029;
}

.contact-container .map-container {
    display: block;
    width: 60%;
    height: calc(100% - 20px);
    padding-bottom: 20px;
    /* background: #E9E0DB; */
}

.contact-container .map-container .map-info {
    height: 100%;
}

.map-info iframe {
    width: 100%;
    min-height: 100%;
    border: none;
}

@media screen and (max-width: 1200px) {
    .contact {
        display: block;
    }

    .contact .contact-container {
        flex-direction: column;
        height: auto;
        overflow-y: unset;
    }

    .contact-container .form-container {
        width: 100%;
    }

    .form-container .form-content {
        padding: 0 20px 40px 20px;
    }

    .form-container .contact-info {
        margin: 0 auto;
        gap: 20px;
    }

    .contact-container .map-container {
        width: 100%;
        display: block;
        max-width: 500px;
        height: 400px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 600px) {
    .contact {
        height: auto;
        padding: 60px 0 0 0;
    }

    .form-container .form-content {
        width: calc(100% - 20px);
        padding: 0 10px 40px 10px;
    }

    .form-container .form-content .form-contact {
        padding: 26px 10px;
        gap: 20px;
    }

    .form-contact .form-row input,
    .form-contact .form-row textarea {
        font-size: 9px;
    }

    .form-contact .form-row button {
        font-size: 10px;
    }

    .contact-info .email-info a,
    .contact-info .phone-info a {
        font-size: 10px;
        padding: 10px;
    }

    .label-error .error-name {
        font-size: 10px;
        font-weight: 500;
    }

    .form-contact .form-row label {
        font-size: 10px;
        font-weight: 500;
    }

    .contact-container .map-container {
        width: calc(100% - 20px);
        display: block;
        padding: 0 10px 20px 10px;
        height: 400px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 400px) {
    .form-container .contact-info {
        flex-direction: column;
        padding: 0 10px;
        width: auto;
    }
}