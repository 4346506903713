.home {
    display: block;
    min-height: calc(100vh - 100px);
    background: #fff;
    padding-top: 100px;
    max-width: 100vw;
    overflow: hidden;
}

@media screen and (max-width: 600px) {
    .home {
        min-height: calc(100vh - 60px);
        padding-top: 60px;
    }
}

/* banner */

.banner {
    display: block;
    height: calc(100vh - 100px);
    width: 100vw;
    overflow: hidden;
}

.banner .banner-container {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.banner-container .text {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    position: relative;
    z-index: 5;
}

.banner-container .text .header {
    width: 50%;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-size: 48px;
    font-weight: 900;
}

.banner-container .text .subheader {
    width: 50%;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-weight: 500;
}

.banner-container .text .header,
.banner-container .text .subheader {
    transform: translateX(-20);
}

.banner-container .content {
    height: 100%;
}

.banner-container .content img {
    position: absolute;
    bottom: 0;
    right: -10px;
    height: 100%;
}

.banner-container .content .dishes {
    z-index: 4;
}

.banner-container .content .dark {
    z-index: 3;
}

.banner-container .content .antenna {
    z-index: 2;
}

.banner-container .content .light {
    z-index: 1;
}

.banner.container .dishes,
.banner.container .dark,
.banner.container .antenna,
.banner.container .light {
    opacity: 0;
    transform: translateX(50);
}

@media screen and (max-width: 1400px) {
    .banner-container .text {
        padding: 20px;
    }

    .banner-container .text .header {
        font-size: 32px;
    }

    .banner-container .text .subheader {
        font-size: 16px;
    }
}

@media screen and (max-width: 600px) {
    .banner {
        height: calc(100vh - 60px);
    }

    .banner-container .text {
        flex-direction: center;
        justify-content: flex-start;
        padding-top: 50%;
    }

    .banner-container .text .header {
        width: 70%;
        font-size: 25px;
    }

    .banner-container .text .subheader {
        width: 70%;
        font-size: 12px;
    }

    .banner-container .content img {
        position: absolute;
        bottom: 0;
        right: -20px;
        height: unset;
        width: 170%;
    }
}

/* activity */

.activity-container {
    display: flex;
    flex-direction: column;
    gap: 100px;
    width: 100vw;
}

.activity-container .activities {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.activity-container .activities .activity {
    width: calc((100% - 40px)/3);
    height: 270px;
}

.activities .activity .activity-content {
    background: #F9F1EC;
    height: 100%;
    border-bottom: 2px solid #760014;
    border-top: 2px solid #F9F1EC;
    border-left: 2px solid #F9F1EC;
    border-right: 2px solid #F9F1EC;
    display: flex;
    width: auto;
    height: calc(100% - 40px);
    padding: 20px;
    color: #000;
    text-decoration: none;
}

.activity-content .img {
    width: 40%;
    background: #fff;
    overflow: hidden;
    position: relative;
}

.activity-content .img img {
    height: 100%;
    width: auto;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: -50%;
    filter: grayscale();
}

.activity-content .name {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
}

.activities  .activity .activity-content:hover {
    background: #E9E0DB;
    border: 2px solid #760014;
}

@media screen and (max-width: 1400px) {
    .activity-container .activities {
        padding: 0 20px;
    }

    .activity-container .activities .activity {
        width: calc((100% - 20px)/2);
        height: 200px;
    }

    .activity-content .name {
        font-size: 16px;
    }

    .activity-content .img img {
        height: auto;
        max-height: 110%;
        width: auto;
        min-width: 100%;
        object-fit: contain;
        position: absolute;
        top: 0;
        left: unset;
        filter: grayscale();
    }
}

@media screen and (max-width: 600px) {
    .activity-container {
        gap: 60px;
    }

    .activity-container .activities {
        padding: 0 20px;
    }

    .activity-container .activities .activity {
        overflow: hidden;
        height: 150px;
        width: 100%;
    }

    .activities .activity .activity-content {
        padding: 0;
        position: relative;
        height: 146px;
    }

    .activity-content .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
    .activity-content .img img {
        width: 100%;
        object-fit: contain;
        position: unset;
        height: auto;
        max-height: unset;
        min-width: unset;
    }

    .activity-content .name {
        justify-content: center;
        align-items: center;
        width: 100%;
        z-index: 1;
        color: #fff;
        padding-left: 0;
        text-align: center;
        font-weight: 900;
        font-size: 16px;
    }
}

/* why us */

.whyYs {
    height: 380px;
    width: 100vw;
    overflow: hidden;
}

.whyUs .whyUs-container {
    display: block;
    width: 100%;
    position: relative;
}

.whyUs-container .content-abs {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.whyUs-container .content-abs .content {
    max-width: 1400px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.whyUs-container .content .header {
    display: flex;
    height: 100%;
    width: 34%;
    background: none;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 900;
    font-size: 64px;
    text-transform: uppercase;
    transform: translate(-50px, 0);
    opacity: 0;
}

.whyUs-container .content .desc-container {
    display: flex;
    align-items: center;
    height: 100%;
    width: 66%;
    background: #fff;
}

.desc-container .description {
    padding-left: 40px;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-size: 24px;
    line-height: 190%;
    transform: translateY(0);
    opacity: 0;
}

.desc-container .description.is-reveal {
    opacity: 1;
}

.whyUs-container .bg {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.whyUs-container .bg .red {
    display: block;
    width: 50%;
    background: #760014;
    height: 380px;
}

.whyUs-container .bg .white {
    display: block;
    width: 50%;
    background: #fff;
    height: 380px;
}

@media screen and (max-width: 1200px) {
    .whyUs {
        height: 100%;
    }

    .whyUs .whyUs-container {
        background: none;
        padding: 0;
        width: auto;
    }

    .whyUs-container .content .header {
        background: #760014;
        padding: 0 20px;
        height: auto;
        text-align: center;
    }

    .whyUs-container .content-abs {
        position: unset;
    }

    .desc-container .description {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 20px;
    }

    .whyUs-container .bg {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .whyUs {
        height: auto;
    }

    .whyUs .whyUs-container {
        background: #760014;
        padding: 40px 20px;
        width: auto;
    }

    .whyUs-container .content-abs {
        position: unset;
    }

    .whyUs-container .content-abs .content {
        flex-direction: column;
    }

    .whyUs-container .content .header {
        padding: 0 20px 40px 0;
        justify-content: left;
        font-size: 32px;
        width: auto;
    }

    .whyUs-container .content .desc-container {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        background: none;
    }

    .desc-container .description {
        font-size: 16px;
        line-height: 150%;
        color: #fff;
        background: none;
        font-weight: 300;
        padding-left: 0;
    }
}