.about {
    display: block;
    min-height: calc(100vh - 100px);
    background: #fff;
    padding-top: 100px;
    max-width: 100vw;
    overflow: hidden;
}

@media screen and (max-width: 600px) {
    .about {
        min-height: calc(100vh - 60px);
        padding-top: 60px;
    }
}

/* banner */

.about-banner {
    width: 100%;
    max-width: 100vw;
    overflow: hidden;
}

.about-banner .banner-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    background: #F9F1EC;
}

.about-banner .banner-container .content {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}

.content .img-column {
    width: calc(100% / 3);
    overflow: hidden;
    position: unset;
    max-height: 900px;
}

.content .img-column .content-img {
    height: 100%;
    position: unset;
    transform: translateX(-20%);
}

.content .banner-text {
    max-width: calc((100% / 3) * 2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 60px;
}

.banner-text .vm-container span {
    padding-left: 120px;
    font-size: 48px;
    color: #760014;
    font-weight: 900;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    text-transform: capitalize;
}

.banner-text .vm-container p {
    padding-left: 120px;
    font-size: 22px;
    font-weight: 400;
    font-family: 'M PLUS Rounded 1c', sans-serif;
}

@media screen and (max-width: 1400px) {
    .banner-text .vm-container span {
        padding-left: 60px;
        padding-right: 20px;
    }

    .banner-text .vm-container p {
        padding-left: 60px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 1000px) {
    .about-banner {
        max-height: none;
        height: auto;
    }

    .about-banner .banner-container .content {
        flex-direction: column;
        gap: 60px;
    }

    .content .img-column {
        width: 100%;
        max-height: 200px;
    }

    .content .img-column .content-img {
        height: unset;
        width: 100%;
        transform: translateX(0);
        transform: translateY(-10%);
    }

    .content .banner-text {
        max-width: 100%;
        padding-bottom: 60px;
    }

    .banner-text .vm-container span {
        padding-left: 40px;
    }

    .banner-text .vm-container p {
        padding-left: 40px;
    }
}

@media screen and (max-width: 600px) {

    .about-banner .banner-container .content {
        flex-direction: column;
        gap: 20px;
    }

    .content .img-column .content-img {
        position: unset;
        transform: translateY(0);
    }

    .content .banner-text {
        max-width: 100%;
        padding-bottom: 20px;
        gap: 20px;
    }

    .banner-text .vm-container span {
        padding-left: 20px;
        font-size: 20px;
        text-align: center;
    }

    .banner-text .vm-container p {
        padding-left: 20px;
        font-size: 10px;
    }
}

/* corporate structure */

.corpo {
    max-width: 100vw;
}

.corpo .chart {
    padding: 100px 0 50px 0;
    width: 100%;
}

.chart .swiper {
    max-width: 1400px;
    margin: 0 auto;
}

.chart .swipes .swipe {
    background: #F9F1EC;
}

.swipe .swipe-title {
    display: block;
    width: auto;
    padding: 60px 0 20px 0;
    text-align: center;
    font-size: 26px;
    font-weight: 600;
    text-transform: capitalize;
}

.swipe .swipe-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swipe .swipe-image img {
    width: 100%;
}

.swipe .swipe-arrow {
    width: calc(100% - 40px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    height: 20px;
    padding: 20px;
}

.swipe .swipe-arrow .num {
    font-size: 20px;
}

.swipe .swipe-arrow img {
    height: 50%;
}

.chart .tree-root,
.chart .tree-orange,
.chart .tree-blue,
.chart .tree-pink,
.chart .tree-green {
    padding: 10px;
    display: inline-block;
    color: black;
    letter-spacing: 1px;
    font-size: 12px;
}

.chart .tree-root {
    background: #760014;
    border-bottom: 2px solid #760014;
    color: white;
    font-weight: bold;
    font-size: 12px;
}

.chart .tree-orange {
    background-image: linear-gradient(-225deg, rgba(247,206,104,0.5) 0%, rgba(251,171,126,0.5) 100%);
    border-bottom: 2px solid #760014;
    font-weight: bold;
}

.chart .tree-blue {
    background-image: linear-gradient(-225deg, rgba(34,225,255,0.5) 0%, rgba(29,143,225,0.5) 100%);
    font-weight: bold;
    border-bottom: 2px solid #760014;
}

.chart .tree-pink {
    background-image: linear-gradient(-225deg, rgba(240,147,251,0.5) 0%, rgba(245,87,122,0.5) 100%);
    font-weight: bold;
    border-bottom: 2px solid #760014;
}

.chart .tree-green {
    background: rgb(56,249,215);
    background: linear-gradient(-225deg, rgba(56,249,215,0.5) 0%, rgba(67,233,123,0.5) 100%);
    font-weight: bold;
    border-bottom: 2px solid #760014;
}

@media screen and (max-width: 1000px) {

    .swipe .swipe-title {
        font-size: 20px;
    }

    .swipe .swipe-arrow .num {
        font-size: 16px;
    }
}

@media screen and (max-width: 600px) {
    .corpo .chart {
        padding: 100px 0 0 0;
    }

    .chart .swiper {
        padding: 10px;
    }

    .swipe .swipe-arrow {
        height: 10px;
    }

    .swipe .swipe-title {
        padding: 30px 0 0 0;
        font-size: 14px;
        /* font-weight: 400; */
    }

    .swipe .swipe-arrow .num {
        font-size: 8px;
    }
}

